import type {AnimationPhase} from '@/components/core/Animation';
import Button from '@/components/core/Button';
import type {FormSpec} from '@/components/form/FormTypes';
import LivanForm from '@/components/form/LivanForm';
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react';
import {InformationCircleIcon} from '@heroicons/react/20/solid';
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import React from 'react';
import {useCallback, type MouseEventHandler, type ReactNode} from 'react';

type ModalConfig = {
  Icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>>;
  iconProps: {
    className: string;
  };
  iconWrapperProps: {
    className: string;
  };
};

const configsByStyleType: Record<StyleType, ModalConfig> = {
  default: {
    Icon: InformationCircleIcon,
    iconProps: {
      className: 'text-green-600',
    },
    iconWrapperProps: {
      className: 'bg-green-100',
    },
  },
  warning: {
    Icon: ExclamationTriangleIcon,
    iconProps: {
      className: 'text-yellow-600',
    },
    iconWrapperProps: {
      className: 'bg-yellow-100',
    },
  },
  destructive: {
    Icon: ExclamationTriangleIcon,
    iconProps: {
      className: 'text-red-600',
    },
    iconWrapperProps: {
      className: 'bg-red-100',
    },
  },
};

export type ModalProps = {
  title: string;
  content?: ReactNode | (() => ReactNode);
  styleType?: StyleType;
  className?: string;
  onCancelClick: MouseEventHandler;
  formSpec: FormSpec;
  cancelButtonText?: string;
  animationPhase: AnimationPhase;
  loading?: boolean;
};

export type ModalType = keyof typeof configsByStyleType;

export default function Modal(props: ModalProps) {
  useSignals();
  const {
    className,
    styleType = 'default',
    title,
    onCancelClick,
    cancelButtonText,
    animationPhase,
    loading,
    formSpec,
  } = props;
  let {content} = props;
  if (typeof content === 'function') {
    content = content();
  }

  const onDialogClose = useCallback(
    function () {
      if (loading) {
        return;
      }
      // @ts-expect-error this is meant for a mouse event handler, but we will never be able to satisfy this with the way headless UI works
      onCancelClick();
    },
    [loading, onCancelClick],
  );

  return (
    <Dialog
      open
      role="dialog"
      onClose={onDialogClose}
      className={clsx('relative z-40', className)}
    >
      <DialogBackdrop
        transition
        className={clsx(
          'fixed inset-0 bg-livan-black bg-opacity-75',
          animationPhase && `Modal-animating-${animationPhase}`,
          animationPhase === 'in' && 'animate-fade-in',
          animationPhase === 'out' && 'animate-fade-out',
        )}
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={clsx(
              'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 w-full sm:max-w-lg',
              animationPhase && `DialogPanel-animating-${animationPhase}`,
              animationPhase === 'in' && 'animate-fade-in',
              animationPhase === 'out' && 'animate-fade-out',
            )}
          >
            <ModalBodyDefault
              styleType={styleType}
              formSpec={formSpec}
              onCancelClick={onCancelClick}
              loading={loading}
              title={title}
              content={content}
              cancelButtonText={cancelButtonText}
            />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

type ModalBodyDefaultProps = {
  title: string;
  styleType?: StyleType;
  content: ReactNode | undefined;
  formSpec: FormSpec;
  onCancelClick: MouseEventHandler;
  cancelButtonText?: string;
  loading?: boolean;
};

function ModalBodyDefault(props: ModalBodyDefaultProps) {
  useSignals();
  const {
    styleType = 'default',
    title,
    onCancelClick,
    cancelButtonText = 'Cancel',
    loading = false,
    content,
    formSpec,
  } = props;

  const {Icon, iconProps, iconWrapperProps} = configsByStyleType[styleType];

  const renderFormBody = useCallback(
    function ({Inputs, inputProps, disabled, loading}) {
      return (
        <React.Fragment>
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div
                {...iconWrapperProps}
                className={clsx(
                  'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                  iconWrapperProps.className,
                )}
              >
                <Icon
                  aria-hidden="true"
                  {...iconProps}
                  className={clsx(iconProps.className, 'h-6 w-6')}
                />
              </div>
              <div className="flex flex-col mt-3 sm:mx-4 sm:mt-0 gap-2 w-full">
                <DialogTitle
                  as="h3"
                  className="font-semibold leading-6 text-center sm:text-left"
                >
                  {title}
                </DialogTitle>
                <div className="Modal-name">
                  {content && <div>{content}</div>}
                  <Inputs {...inputProps} />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 px-4 py-3 flex sm:px-6 gap-2 justify-end">
            <Button
              disabled={loading}
              flavor="link"
              onClick={onCancelClick}
            >
              {cancelButtonText}
            </Button>
            <Button
              type="submit"
              loading={loading}
              disabled={disabled}
              styleType={styleType}
            >
              {formSpec.submitText || 'Confirm'}
            </Button>
          </div>
        </React.Fragment>
      );
    },
    [
      title,
      content,
      cancelButtonText,
      onCancelClick,
      Icon,
      iconProps,
      iconWrapperProps,
      styleType,
      formSpec.submitText,
    ],
  );

  return (
    <LivanForm
      formSpec={formSpec}
      renderBody={renderFormBody}
    />
  );
}
