import Button from '@/components/core/Button';
import FormInput from '@/components/form/FormInput';
import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import {useCallback, type ChangeEventHandler, type KeyboardEventHandler} from 'react';

type TextInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue'
> & {
  IconComponent?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>
  >;
} & XOR<
    {
      buttonText: string;
      alignButtonLeft: boolean;
    },
    {
      label: string;
      description?: string;
    }
  >;

export default FormInput<TextInputProps, string>({
  defaultValue: '',
  Component: function TextInput(props) {
    useSignals();
    const {
      className,
      errors,
      IconComponent,
      label,
      buttonText,
      alignButtonLeft,
      name,
      description,
      onChange,
      onBlur,
      required,
      validator,
      type = 'text',
      onInputRef,
      value,
      ...inputProps
    } = props;

    const ButtonComponent = buttonText && (
      <Button
        type="submit"
        rounded={false}
        className={clsx('shrink-0', alignButtonLeft ? 'rounded-l-md' : 'rounded-r-md')}
      >
        {buttonText}
      </Button>
    );

    const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(function (event) {
      if (event.key === 'Enter') {
        // don't let enter on input submit the form; must use ctrl+enter as defined in a form
        event.preventDefault();
      }
    }, []);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      function (event) {
        const newValue = event.target.value;
        onChange({
          value: newValue,
          hasInteracted: false, // wait for blur handler to mark as interacted
        });
      },
      [onChange],
    );

    return (
      <div className={clsx(className, 'flex flex-col w-full gap-1')}>
        {label && (
          <label
            htmlFor={name}
            className={clsx('block font-medium', errors.length && 'text-red-700')}
          >
            {label}
          </label>
        )}
        <div className={clsx(label && 'mt-1', 'relative flex w-full')}>
          {IconComponent && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <IconComponent
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </div>
          )}
          {alignButtonLeft && ButtonComponent}
          <input
            {...inputProps}
            id={name}
            type={type}
            name={name}
            ref={onInputRef}
            required={required}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            value={value}
            className={clsx(
              IconComponent && 'pl-10',
              buttonText
                ? alignButtonLeft
                  ? 'rounded-r-md flex-1'
                  : 'rounded-l-md flex-1'
                : 'rounded-md w-full',
              'px-2.5 text-black shadow-sm sm:leading-6 border-0 py-1.5 ring-1 ring-inset ring-gray-300 focus:ring-livan-black focus:ring-2 focus:ring-inset',
              errors.length && 'ring-red-700 focus:ring-red-700',
            )}
            aria-invalid={!!errors.length}
            aria-describedby={errors.length ? `${name}-error` : undefined}
          />
          {!alignButtonLeft && ButtonComponent}
        </div>
        {description && <div className="text-sm text-gray-800">{description}</div>}
        {errors.length ? (
          <div className="text-red-700 animate-fade-in gap-2">
            {errors.map((error, i) => {
              return (
                <div
                  id={`${name}-error-${i}`}
                  key={`${error}-${i}`}
                >
                  {error}
                </div>
              );
            })}
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
      </div>
    );
  },
});
