import SwitchInput from '@/components/form/SwitchInput';
import TextInput from '@/components/form/TextInput';
import type {ZodTypeAny} from 'zod';

export type OnInputChange<V> = (inputValue: InputValue<V>) => void;

export type FormSpec = {
  inputSpecs: InputSpec<any>[];
  onSubmit(values: Record<string, any>): void | Promise<void>;
  submitText?: string;
};

export const InputComponentsByType = {
  text: TextInput,
  password: TextInput,
  switch: SwitchInput,
};

export type InputType = keyof typeof InputComponentsByType;

export type InputSpec<V> = {
  name: HTMLInputElement['name'];
  label: string;
  description?: string;
  type: InputType;
  required?: boolean;
  validator?: ZodTypeAny;
  autoComplete?: HTMLInputElement['autocomplete'];
  spellCheck?: HTMLInputElement['spellcheck'];
  autoCapitalize?: HTMLInputElement['autocapitalize'];
} & {
  type: InputType;
  IconComponent?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>
  >;
  defaultValue?: V;
};

export type InputValue<V> = {
  name: string;
  value: V;
  errors: string[];
  hasChanged: boolean;
  hasInteracted: boolean;
};
