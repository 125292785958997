import FormInput from '@/components/form/FormInput';
import type {OnInputChange} from '@/components/form/FormTypes';
import {Switch} from '@headlessui/react';
import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import {useCallback, useState} from 'react';

type SwitchInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue'
> & {
  label: string;
  description?: string;
};

export default FormInput<SwitchInputProps, boolean>({
  defaultValue: false,
  Component: function SwitchInput(props) {
    useSignals();
    const {
      className,
      errors,
      label,
      name,
      description,
      onChange,
      onBlur,
      required,
      validator,
      onInputRef,
      value,
      ...inputProps
    } = props;

    const handleChange = useCallback(
      function (newValue: boolean) {
        onChange({
          value: newValue,
          hasInteracted: true,
        });
      },
      [onChange],
    );

    return (
      <div className={clsx(className, 'flex items-center')}>
        <div className="flex flex-col flex-grow">
          <strong>{label}</strong>
          {description && <div className="text-sm text-gray-800">{description}</div>}
        </div>
        <Switch
          checked={value}
          onChange={handleChange}
          className="ml-2 group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out data-[checked]:bg-green-600"
        >
          <span className="sr-only">Toggle "{label}"</span>
          <span className="pointer-events-none relative inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
            <span
              aria-hidden="true"
              className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
            >
              <svg
                fill="none"
                viewBox="0 0 12 12"
                className="size-3 text-gray-400"
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              aria-hidden="true"
              className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
            >
              <svg
                fill="currentColor"
                viewBox="0 0 12 12"
                className="size-3 text-green-600"
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
      </div>
    );
  },
});
