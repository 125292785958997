import type {OnInputChange} from '@/components/form/FormTypes';
import {useEffectOnce} from '@/utils/react';
import {batch, computed, useSignal} from '@preact/signals-react';
import {useSignals} from '@preact/signals-react/runtime';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  type ChangeEventHandler,
  type FocusEventHandler,
  type Ref,
  type RefObject,
} from 'react';

import {type ForwardedRef, type ForwardRefRenderFunction} from 'react';
import type {ZodTypeAny} from 'zod';

type FormInputProps<V> = {
  name: HTMLInputElement['name'];
  label?: string;
  defaultValue?: V;
  onChange: OnInputChange<V>;
  validator?: ZodTypeAny;
  required?: HTMLInputElement['required'];
  errors: string[] | undefined;
};

type OnChange<V> = (args: {value: V; hasInteracted: boolean}) => void;

type ComponentProps<V> = Omit<FormInputProps<V>, 'onChange' | 'errors'> & {
  onChange: OnChange<V>;
  onInputRef: Ref<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  errors: string[];
  value: V;
};

export type FormInputRef = {
  focus(): void;
  blur(): void;
  reset(): void;
  validate(): void;
};

export default function FormInput<P, V>({
  Component,
  defaultValue: inputDefaultValue,
}: {
  Component: ForwardRefRenderFunction<FormInputRef, P & ComponentProps<V>>;
  defaultValue: V;
}) {
  return forwardRef(function FormInputWrapper(
    props: P & FormInputProps<V>,
    ref: ForwardedRef<FormInputRef>,
  ) {
    useSignals();
    const inputRef = useRef<HTMLInputElement>(null);

    const {
      name,
      defaultValue = inputDefaultValue,
      validator,
      onChange,
      errors,
      required,
      label,
    } = props;

    const valueSignal = useSignal<V>(defaultValue);

    useImperativeHandle(ref, () => {
      return {
        focus() {
          inputRef.current?.focus();
        },
        blur() {
          inputRef.current?.blur();
        },
        reset() {
          const value = defaultValue;
          valueSignal.value = value;
          const errors = validate(value);
          onChange?.({
            name,
            value,
            hasChanged: false,
            errors,
            hasInteracted: false,
          });
        },
        validate() {
          const value = valueSignal.peek();
          const errors = validate(value);
          onChange?.({
            name,
            value,
            hasChanged: false,
            errors,
            hasInteracted: true,
          });
        },
      };
    });

    const hasChangedSignal = computed(() => {
      return defaultValue !== valueSignal.value;
    });

    const validate = useCallback(
      function (value: V) {
        const errors: string[] = [];
        if (validator) {
          const result = validator.safeParse(value);
          if (result?.error) {
            errors.push(...result.error.issues.map(({message}) => message));
          }
          if (result?.data != null) {
            value = result.data;
          }
        }

        if (!errors.length && typeof value === 'string' && value.length <= 0 && required) {
          errors.push(`${label || 'Field'} is required`);
        }
        return errors;
      },
      [label, required, validator],
    );

    const handleChange = useCallback<OnChange<V>>(
      function ({value, hasInteracted = true}) {
        const errors = validate(value);
        batch(() => {
          valueSignal.value = value;
        });

        onChange?.({
          name,
          value,
          hasChanged: hasChangedSignal.peek(),
          hasInteracted,
          errors,
        });
      },
      [onChange, hasChangedSignal, name, validate, valueSignal],
    );

    const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
      function (event) {
        const value = valueSignal.peek();
        const errors = validate(value);
        onChange?.({
          name,
          value,
          hasChanged: hasChangedSignal.peek(),
          hasInteracted: true,
          errors,
        });
      },
      [onChange, hasChangedSignal, name, validate, valueSignal],
    );

    useEffectOnce(() => {
      const value = defaultValue;
      const errors = validate(value);
      onChange?.({
        name,
        value,
        hasChanged: false,
        hasInteracted: false,
        errors,
      });
    });

    return Component(
      {
        ...props,
        onInputRef: inputRef,
        onChange: handleChange,
        onBlur: handleBlur,
        errors: errors || [],
        value: valueSignal.value,
      },
      ref,
    );
  });
}
